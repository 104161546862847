/* src/global.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #282a36;
  color: #f8f8f2;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

/* Navbar styles */
.navbar {
  font-size: 15px;
  font-weight: bolder;
  background-color: #44475a;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-list {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

.nav-item {
  cursor: pointer;
  color: #8be9fd;
  transition: color 0.3s ease;
  padding: 0.5rem 1rem;
}

.nav-item:hover {
  color: #ff79c6;
}

/* Section styles */
.section {
  margin-bottom: 3rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.section.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .section {
    padding: 3rem 0;
  }

  .nav-item {
    padding: 0.5rem;
  }
}

